import { getCurrentInstance } from 'vue'

/**
 * @param {{ proxy: Vue }?} instance
 * @return {Hook.VueStore}
 */
export function useStore(instance) {
  const vm = instance ?? getCurrentInstance()
  return vm.proxy.$store
}

/**
 *
 * @param {{ proxy: Vue }?} instance
 * @return {(function(*, *, *): void)|*}
 */
export function useSet(instance) {
  const vm = instance ?? getCurrentInstance()
  return function(o, k, v) {
    vm.proxy.$set(o, k, v)
  }
}

/**
 @param {{ proxy: Vue }?} instance
 * @returns {(function(*, *): void)|*}
 */
export function useDelete(instance) {
  const vm = instance ?? getCurrentInstance()
  return function(o, k) {
    vm.proxy.$delete(o, k)
  }
}

/**
 * 聚合Hook,避免重复调用getCurrentInstance
 * @param {Hook.Hooks[]?} hooks
 * @param {{ proxy: Vue }?} instance
 * @return {Hook.UseHooksRes}
 */
export default function useHooks(hooks, instance) {
  hooks = hooks ?? ['set', 'store']
  instance = instance ?? getCurrentInstance()

  /**
   * @type {Hook.UseHooksRes}
   */
  const use = {
    proxy: instance.proxy
  }
  hooks.forEach((key) => {
    switch (key) {
      case 'set':
        use.$set = useSet(instance)
        break
      case 'delete':
        use.$delete = useDelete(instance)
        break
      case 'store':
        use.$store = useStore(instance)
        break
    }
  })
  return use
}
