export const State = {
  Valid: 'VALID',
  InValid: 'INVALID'
}

export const BatchSetDialogType = {
	PrintTemplate: 'pt'
}
export const StateOptions = [
  { label: '启用', value: State.Valid },
  { label: '禁用', value: State.InValid }
]
